import axios from 'axios';
import { DEFAULT_TIMEOUT } from './api.config';

export const setAuthHeader = token => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const baseApi = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: DEFAULT_TIMEOUT, // ms
  headers: {
    'Content-Type': 'application/json',
  },
});

// Mock api

export const api = axios.create({
  baseURL: 'https://48eea6d6-7c81-47df-baf4-8d01e1ad763c.mock.pstmn.io/',
  timeout: DEFAULT_TIMEOUT, // ms
  headers: {
    'Content-Type': 'application/json',
  },
});
