export const updatePartOfState = (state = {}, values = {}) => ({
  ...state,
  ...values,
});

export const handleUnknownActionType = action => {
  throw new Error(`This action "${action.type}" isn't supported`);
};

export const putElementInBeginningOfArray = (element, array = []) => [element, ...array];

export const changeStatus = ({ data, id, note = null, status }) => {
  const result = [];
  data.map(el => {
    if (el?.id === id) {
      result.push({ ...el, ...{ status, note } });
    } else {
      result.push(el);
    }
  });
  return result;
};
