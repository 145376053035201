// Profile

export const GET_CURRENT_USER_PROFILE = '/current-profile';

// Control panel

export const SEARCH_CONTROL_PANEL_DETAIL = '/control-panel';

// Age check

export const AGE_CHECK_SEARCH = `/age-check/search`;
export const AGE_CHECK_RETRIEVE_SINGLE = `/age-check/retrieve-single`;
export const AGE_CHECK_REJECT = `/age-check/reject`;
export const AGE_CHECK_POSTPONE = `/age-check/postpone`;
export const AGE_CHECK_CONFIRM = `/age-check/confirm`;
export const AGE_CHECK_EDIT_AGE = '/age-check/edit-age';

// Dashboard

export const DASHBOARD_DETAIL = '/dashboard';
export const DASHBOARD_DYNAMICS_DETAIL = '/dashboard/dynamics';

// Images approval

export const IMAGES_APPROVAL_SEARCH = '/images-approval/search';
export const IMAGES_APPROVAL_DETAIL = '/images-approval/retrieve-single';
export const IMAGES_APPROVAL_REJECT = '/images-approval/reject';
export const IMAGES_APPROVAL_CONFIRM = '/images-approval/confirm';
export const IMAGES_APPROVAL_POSTPONE = '/images-approval/postpone';

// Text check

export const TEXT_CHECK_SEARCH = '/text-check/search';
export const BAD_WORDS_SEARCH = '/text-check/catalog/search';
export const BAD_WORDS_ADD = '/text-check/catalog/add';
export const BAD_WORDS_DELETE = '/text-check/catalog/delete';
export const TEXT_CHECK_RETRIEVE_SINGLE = '/text-check/retrieve-single';
export const TEXT_CHECK_CONFIRM = '/text-check/confirm';
export const TEXT_CHECK_BLOCK = '/text-check/block-user';
export const TEXT_CHECK_UNBLOCK = '/text-check/unblock-user';
export const TEXT_CHECK_REJECT = '/text-check/reject';
export const TEXT_CHECK_WARN = '/text-check/warn-user';
export const TEXT_CHECK_POSTPONE = '/text-check/postpone';

// Annual income

export const ANNUAL_INCOME_SEARCH = '/annual-income/search';
export const ANNUAL_INCOME_CONFIRM = '/annual-income/confirm';
export const ANNUAL_INCOME_REJECT = '/annual-income/reject';
export const ANNUAL_INCOME_POSTPONE = '/annual-income/postpone';

// Feedback

export const FEEDBACK_SEARCH = '/feedback/search';
export const FEEDBACK_DETAILS = '/feedback/retrieve-single';
export const FEEDBACK_ACKNOWLEDGE = '/feedback/acknowledge';
export const FEEDBACK_POSTPONE = '/feedback/postpone';
// History

export const HISTORY_SEARCH = '/history/search';
export const USERS_DROP_DOWN = '/users/search-for-dropdown';
export const HISTORY_RETRIEVE_SINGLE = '/history/retrieve-single';
export const HISTORY_GENERATE_FILE = '/history/generate-file';

// Violation report

export const VIOLATION_REPORT_SEARCH = '/complaints/search';
export const VIOLATION_REPORT_DETAIL = '/complaints/retrieve-single';
export const VIOLATION_REPORT_ACTION = '/complaints/interactions/search';
export const VIOLATION_REPORT_BLOCK_ABUSER = '/complaints/block-abuser';
export const VIOLATION_REPORT_UNBLOCK_ABUSER = '/complaints/unblock-abuser';
export const VIOLATION_REPORT_WARN_ABUSER = '/complaints/warn-abuser';
export const VIOLATION_REPORT_REJECT_ABUSER = '/complaints/reject';
export const VIOLATION_REPORT_POSTPONE_ABUSER = '/complaints/postpone';

// Notifications template

export const MESSAGE_TEMPLATES = '/message-templates';
export const CREATE_NEW_TOPIC = '/message-templates';
export const MESSAGE_SECTION_TOPIC = '/message-templates/sections/{section}/topics';
export const DELETE_TOPIC = '/message-templates';
export const UPDATE_TOPIC = '/message-templates';
export const MESSAGE_TEMPLATES_DATA = '/message-templates';

// Users

export const SEARCH_USERS = '/users/search';
export const USERS_DETAILS = '/users/retrieve-single';
export const USERS_ACTION_SEARCH = '/users/actions/search';
export const USERS_BLOCK = '/users/block';
export const USERS_UNBLOCK = '/users/unblock';
export const USERS_WARN = '/users/warn';
export const USERS_MESSAGES_HISTORY = '/messages';
export const USERS_MESSAGES_INDIVIDUAL = '/messages/individual';
export const USERS_EDIT_DATA = '/users/{userIdHash}/update-profile';

// Statistics

export const STATISTICS_SEARCH = '/statistics';

// Staff

export const STAFF_LIST = '/staff/search';
export const STAFF_CREATE = '/staff/add';
export const STAFF_PROFILE = '/staff/retrieve-profile';
export const STAFF_PROFILE_ITEM = '/staff/retrieve-single';
export const STAFF_PROFILE_UPDATE = '/staff/update';
export const STAFF_PROFILE_UPDATE_STAFF = '/staff/update-staff';
export const STAFF_PROFILE_UPDATE_AVATAR = '/staff-avatars/upload';
export const STAFF_PROFILE_DELETE_AVATAR = '/staff-avatars';
export const STAFF_PROFILE_GET_AVATAR = '/staff-avatars';
// Data options

export const DATA_OPTIONS = '/options/';

// Setting

export const SETTINGS_GET_MODE = '/app-modes';
export const SETTINGS_SET_MODE = '/app-modes/set';
export const SETTINGS_GET_FREE_MODE = '/subscription/settings';
export const SETTINGS_SET_FREE_MODE = '/subscription/settings';

// Admin messages

export const ADMIN_MESSAGES_GET = '/messages';
export const ADMIN_MESSAGES_SET = '/messages/all';
