export const getLocationLanguage = (defaultLang, supportedLanguages) => {
  // default language value
  let lang = supportedLanguages.find(elem => elem.id === defaultLang)?.language;

  // browser language value
  let browserLanguage = window.navigator
    ? window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage
    : defaultLang;
  browserLanguage = browserLanguage.substr(0, 2).toLowerCase();

  // check if the language is supported
  supportedLanguages.forEach(elem => {
    if (browserLanguage === elem.abbreviation) {
      lang = elem.language;
    }
  });

  // return the result
  return lang;
};
