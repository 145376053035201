import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useAccessToken = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [state, setState] = React.useState({
    accessToken: null,
    error: null,
  });

  React.useEffect(() => {
    (async () => {
      try {
        const resultToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });

        setState({ ...state, accessToken: resultToken });
      } catch (error) {
        setState({ ...state, error });
      }
    })();
  }, []);

  return state;
};
