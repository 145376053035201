import React from 'react';
import { Spin } from 'antd';
import { oneOf } from 'prop-types';
import classNames from 'classnames/bind';
import styles from './loader.module.scss';

const cx = classNames.bind(styles);

const sizes = {
  LARGE: 'large',
  SMALL: 'small',
};

export const Loader = ({ size = sizes.LARGE }) => (
  <div className={cx('container', size)}>
    <Spin />
  </div>
);

Loader.propTypes = {
  size: oneOf([sizes.LARGE, sizes.SMALL]),
};
