import React from 'react';
import Error from '../error';
import childrenType from '../../types/children';

class ErrorBoundary extends React.Component {
  state = {
    error: false,
  };

  componentDidCatch() {
    this.setState({ error: true });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    return error ? <Error /> : children;
  }
}

ErrorBoundary.propTypes = {
  children: childrenType.isRequired,
};

export default ErrorBoundary;
