import React from 'react';
import * as routes from '../constant/routes';

const PageDashBoard = React.lazy(() => import('../pages/page-dashboard'));
const PageHistory = React.lazy(() => import('../pages/page-history'));
const PageUsers = React.lazy(() => import('../pages/page-users'));
const PageUserDetail = React.lazy(() => import('../pages/page-user-detail'));
const PageUserActions = React.lazy(() => import('../pages/page-user-actions'));
const PageStaff = React.lazy(() => import('../pages/page-staff'));
const PageStaffDetail = React.lazy(() => import('../pages/page-staff-detail'));
const PageStatistics = React.lazy(() => import('../pages/page-statistics'));
const PageSettings = React.lazy(() => import('../pages/page-settings'));
const PageControlPanel = React.lazy(() => import('../pages/page-control-panel'));

const PageAgeCheck = React.lazy(() => import('../pages/page-age-check'));
const PageImagesApproval = React.lazy(() => import('../pages/page-images-approval'));
const PageAnnualIncome = React.lazy(() => import('../pages/page-annual-income'));
const PageTextCheck = React.lazy(() => import('../pages/page-text-check'));
const PageTextCheckDetail = React.lazy(() => import('../pages/page-text-check-detail'));
const PageBadWordsCatalog = React.lazy(() => import('../pages/page-bad-words-catalog'));
const PageViolationReport = React.lazy(() => import('../pages/page-violation-report'));
const PageViolationReportDetail = React.lazy(() => import('../pages/page-violation-report-detail'));
const PageViolationReportActions = React.lazy(() =>
  import('../pages/page-violation-report-actions'),
);
const PageFeedBack = React.lazy(() => import('../pages/page-feedback'));
const PageFeedBackDetail = React.lazy(() => import('../pages/page-feedback-detail'));
const PageAdminMessages = React.lazy(() => import('../pages/page-admin-messages'));
const PageNotificationTemplates = React.lazy(() => import('../pages/page-notification-templates'));

// Not found

const PageNotFound = React.lazy(() => import('../pages/page-not-found'));

// Auth

// const PageLogin = React.lazy(() => import('../pages/page-login/page-login'));

// !!! Code commented out for task KA-182

export const MAIN_LAYOUTS_ROUTES = [
  // {
  //   path: routes.PAGE_DASHBOARD,
  //   component: PageDashBoard,
  //   exact: true,
  // },
  {
    path: routes.PAGE_MAIN,
    component: PageDashBoard,
    exact: true,
  },
  {
    path: routes.PAGE_HISTORY,
    component: PageHistory,
    exact: true,
  },
  {
    path: `${routes.PAGE_HISTORY}/:id`,
    component: PageHistory,
    exact: true,
  },
  {
    path: routes.PAGE_USERS,
    component: PageUsers,
  },
  {
    path: `${routes.PAGE_USER_DETAIL}/:id`,
    component: PageUserDetail,
  },
  // {
  //   path: `${routes.PAGE_USER_ACTIONS}/:id`,
  //   component: PageUserActions,
  // },
  {
    path: routes.PAGE_STAFF,
    component: PageStaff,
  },
  {
    path: `${routes.PAGE_STAFF_PROFILE}`,
    component: PageStaffDetail,
  },
  {
    path: `${routes.PAGE_STAFF_DETAIL}/:id`,
    component: PageStaffDetail,
  },
  {
    path: routes.PAGE_STATISTICS,
    component: PageStatistics,
  },
  {
    path: routes.PAGE_SETTINGS,
    component: PageSettings,
  },
  {
    path: routes.PAGE_AGE_CHECK,
    component: PageAgeCheck,
  },
  {
    path: routes.PAGE_IMAGES_APPROVAL,
    component: PageImagesApproval,
  },
  {
    path: routes.PAGE_ANNUAL_INCOME,
    component: PageAnnualIncome,
  },
  {
    path: routes.PAGE_TEXT_CHECK,
    component: PageTextCheck,
  },
  {
    path: `${routes.PAGE_TEXT_CHECK_DETAIL}/:id`,
    component: PageTextCheckDetail,
  },
  {
    path: routes.PAGE_BAD_WORDS_CATALOG,
    component: PageBadWordsCatalog,
  },
  {
    path: routes.PAGE_VIOLATION_REPORT,
    component: PageViolationReport,
  },
  {
    path: `${routes.PAGE_VIOLATION_REPORT_DETAIL}/:id`,
    component: PageViolationReportDetail,
  },
  {
    path: `${routes.PAGE_INTERACTIONS_BETWEEN_USERS}/:reporter/:reported/:report`,
    component: PageViolationReportActions,
  },
  {
    path: routes.PAGE_FEEDBACK,
    component: PageFeedBack,
  },
  {
    path: `${routes.PAGE_FEEDBACK_DETAIL}/:id`,
    component: PageFeedBackDetail,
  },
  {
    path: routes.PAGE_CONTROL_PANEL,
    component: PageControlPanel,
  },
  {
    path: routes.PAGE_MESSAGES,
    component: PageAdminMessages,
  },
  {
    path: routes.PAGE_NOTIFICATION_TEMPLATES,
    component: PageNotificationTemplates,
  },
];

export const NOT_FOUND_ROUTE = [{ path: '*', component: PageNotFound }];

// export const AUTH_LAYOUTS_ROUTES = [
//   {
//     path: routes.PAGE_LOGIN,
//     component: PageLogin,
//   },
// ];
