import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { PAGE_MAIN } from '../../constant/routes';
import childrenType from '../../types/children';

export const UseAuth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const history = useHistory();

  const onRedirectCallback = appState => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.location.origin}${PAGE_MAIN}`}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      cacheLocation="localstorage"
      scope="openid profile email offline_access"
    >
      {children}
    </Auth0Provider>
  );
};

UseAuth0ProviderWithHistory.propTypes = {
  children: childrenType,
};
