import React from 'react';
import { Layout } from 'antd';
import classNames from 'classnames/bind';
import childrenType from '../../types/children';
import { LANGUAGE } from '../../services/language';
import { ReactComponent as Logo } from '../../static/icon/logo.svg';
import styles from './auth-layout.module.scss';

const cx = classNames.bind(styles);

const { pleaseOpenWeb } = LANGUAGE;

export const AuthLayout = ({ children }) => (
  <div className={cx('wrapper')}>
    <Layout className={cx('layout')}>
      <Layout.Content className={cx('content')}>{children}</Layout.Content>
    </Layout>
    <Layout className={cx('layout-mobile')}>
      <Logo />
      <h2 className={cx('content')}>{pleaseOpenWeb}</h2>
    </Layout>
  </div>
);

AuthLayout.propTypes = {
  children: childrenType,
};
