import { LANGUAGE } from '../services/language';

const { error, pageError } = LANGUAGE;
// Success

export const SUCCESS_COPY_EMAIL = LANGUAGE.emailCopiedSuccessfully;

// Error

export const ERROR_COPY_EMAIL = error.emailCopyError;
export const ERROR_LOADING_DATA_AGE_CHECK = pageError.ageCheckPageDataLoadingError;
export const ERROR_GENERAL = pageError.generalErrorHasOccurred;
export const ERROR_LOADING_PROFILE_DATA = pageError.errorLoadingProfileData;
export const ERROR_DASHBOARD_SEARCH_DATA = pageError.errorLoadingDashboardData;
export const ERROR_IMAGE_APPROVAL_SEARCH_DATA = pageError.errorLoadingImageApproveData;
export const ERROR_LOADING_TEXT_CHECK_DATA = pageError.errorLoadingTextCheckData;
export const ERROR_LOADING_ANNUAL_INCOME = pageError.errorLoadingDataAnnualIncome;
export const ERROR_LOADING_FEEDBACK = pageError.errorLoadingDataFeedback;
export const ERROR_LOADING_FEEDBACK_DETAILS = pageError.errorLoadingDataFeedbackDetails;
export const ERROR_LOADING_HISTORY_LIST = pageError.errorLoadingHistory;
export const ERROR_LOADING_CONTROL_PANEL = pageError.controlPanelPageLoadingError;
export const ERROR_LOADING_VIOLATION_MESSAGE = pageError.errorLoadingDataViolationMessage;
export const ERROR_LOADING_VIOLATION_DETAILS_MESSAGE =
  pageError.errorLoadingDataViolationMessageDetails;
export const ERROR_LOADING_VIOLATION_ACTION_MESSAGE =
  pageError.errorLoadingDataViolationMessageAction;
export const ERROR_LOADING_BAD_WORDS = pageError.errorLoadingBadWordsPage;
export const ERROR_LOADING_TEXT_CHECK_DETAIL_PAGE = pageError.errorLoadingPageTextCheck;
export const ERROR_LOADING_USERS_DETAIL_PAGE = pageError.errorLoadingPageUsersDetails;
export const ERROR_LOADING_STATISTICS_PAGE = pageError.errorLoadingStatisticsPage;
export const ERROR_LOADING_STAFF_DATA = pageError.errorLoadingStaffPage;
export const ERROR_LOADING_NOTIFICATION_DATA = pageError.errorLoadingNotificationTemplatesPage;

export const TIMEOUT_ERROR = pageError.thePreviousMessageSending;
export const TEMPLATE_ALREADY_EXISTS_ERROR = pageError.templateAlreadyExistsError;
export const FORBIDDEN_CHANGE_TOPIC_ERROR = pageError.forbiddenChangeTopicError;
export const TEMPLATE_SPECIFIED_ID_NOT_EXIST_ERROR = pageError.templateWithSpecifiedIdNotExist;
export const DEFAULT_ERROR = pageError.anErrorOccurredWhileSending;
export const USER_NOT_FOUND_ERROR = pageError.userNotFound;
export const GET_USER_AVATAR_ERROR = pageError.errorGettingUrlUserAvatar;
export const ADMIN_NOT_AUTHORIZATION_ERROR = pageError.administratorIsNotAuthorized;

// Warning

export const WARNING_NO_DATA = error.noData;
