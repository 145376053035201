import { baseApi, setAuthHeader } from '../../services/api';
import { STAFF_PROFILE_GET_AVATAR, STAFF_PROFILE } from '../../constant/endpoints';
import * as actions from './actions';

export const getAdminAvatar = async ({ dispatch, accessToken }) => {
  dispatch(actions.getAvatarLoading());

  try {
    const { data } = await baseApi.get(STAFF_PROFILE_GET_AVATAR, setAuthHeader(accessToken));

    dispatch(actions.getAvatarSuccess({ data: data.data }));
  } catch (error) {
    dispatch(actions.getAvatarError(error?.response));
  }
};

export const retrieveProfile = async ({ dispatch, accessToken }) => {
  dispatch(actions.initStaffProfileLoading());

  try {
    const { data } = await baseApi.post(STAFF_PROFILE, {}, setAuthHeader(accessToken));

    dispatch(actions.initStaffProfileSuccess({ data: data.data }));
  } catch (error) {
    dispatch(actions.initStaffProfileError(error?.response));
  }
};
