import React from 'react';
import { string } from 'prop-types';
import { ErrorView } from './error.view';

const Error = ({ error }) => {
  const updatePage = () => {
    window.location.reload();
  };

  return <ErrorView error={error} updatePage={updatePage} />;
};

Error.propTypes = {
  error: string,
};

export default Error;
