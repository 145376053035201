import React from 'react';
import { Layout } from 'antd';
import classNames from 'classnames/bind';
import childrenType from '../../types/children';
import { MainMenu } from '../../components/main-menu';
import { LANGUAGE } from '../../services/language';
import { ReactComponent as Logo } from '../../static/icon/logo.svg';
import styles from './main-layout.module.scss';

const cx = classNames.bind(styles);

const MAX_WIDTH = 255;
const MIN_WIDTH = 70;

const { pleaseOpenWeb } = LANGUAGE;

export const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = React.useState(true);

  return (
    <div className={cx('wrapper')}>
      <Layout className={cx('layout')}>
        <Layout.Sider width={collapsed ? MAX_WIDTH : MIN_WIDTH}>
          <MainMenu setCollapsed={setCollapsed} collapsed={collapsed} />
        </Layout.Sider>
        <Layout.Content className={cx('content')}>{children}</Layout.Content>
      </Layout>
    </div>
  );
};

MainLayout.propTypes = {
  children: childrenType,
};
