import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Avatar, Row, Skeleton, Space, Tooltip } from 'antd';
import { func, bool, string, objectOf, any } from 'prop-types';
import classNames from 'classnames/bind';
import { ReactComponent as DashboardIcon } from '../../static/icon/dashboard_icon.svg';
import { ReactComponent as ControlPaneIcon } from '../../static/icon/control_panel_icon.svg';
import { ReactComponent as HistoryIcon } from '../../static/icon/history_icon.svg';
import { ReactComponent as UsersIcon } from '../../static/icon/users_icon.svg';
import { ReactComponent as StaffIcon } from '../../static/icon/staff_icon.svg';
import { ReactComponent as StatisticsIcon } from '../../static/icon/statistics_icon.svg';
import { ReactComponent as SettingsIcon } from '../../static/icon/settings_icon.svg';
import { ReactComponent as Logo } from '../../static/icon/logo.svg';
import { ReactComponent as LogoCollapsed } from '../../static/icon/logo-collapsed.svg';
import { ReactComponent as Toggle } from '../../static/icon/toggle_icon.svg';
import * as routes from '../../constant/routes';
import { LANGUAGE } from '../../services/language';
import { textTrimming } from '../../services/text-actions';
import styles from './main-menu.module.scss';

const cx = classNames.bind(styles);
const { SubMenu } = Menu;

export const MainMenuView = ({
  collapsed,
  setCollapsed,
  name,
  familyName,
  id,
  email,
  avatar,
  role,
  loading,
  error,
}) => {
  const location = useLocation();

  const searchValueSubMenu = () => {
    switch (location.pathname) {
      case routes.PAGE_AGE_CHECK:
        return true;

      case routes.PAGE_IMAGES_APPROVAL:
        return true;

      case routes.PAGE_ANNUAL_INCOME:
        return true;

      case routes.PAGE_TEXT_CHECK:
        return true;

      case routes.PAGE_VIOLATION_REPORT:
        return true;

      case routes.PAGE_FEEDBACK:
        return true;

      case routes.PAGE_CONTROL_PANEL:
        return true;

      case routes.PAGE_MESSAGES:
        return true;

      default:
        return false;
    }
  };

  return (
    <>
      {role ? (
        <>
          {collapsed ? (
            <Row className={cx('container-collapsed')}>
              <Row className={cx('row-logo')}>
                <Link to={routes.PAGE_MAIN}>
                  <Logo />
                </Link>
              </Row>

              {loading || avatar === undefined ? (
                <Space className={cx('row-profile-skeleton')}>
                  <Skeleton.Avatar active size={45} />
                  <div>
                    <Skeleton.Input
                      className={cx('skeleton-input')}
                      style={{ width: 150, height: 15 }}
                      active
                      size={52}
                    />
                    <Skeleton.Input
                      className={cx('skeleton-input')}
                      style={{ width: 150, height: 15 }}
                      active
                      size={52}
                    />
                  </div>
                </Space>
              ) : (
                <Link to={`${routes.PAGE_STAFF_PROFILE}`} id="profile-menu">
                  <Row className={cx('row-profile', error && 'row-profile-error')}>
                    <Avatar size={45} src={avatar} />
                    <div className={cx('row-profile-content')}>
                      <h3>{`${name || ''} ${familyName || ''}`}</h3>
                      <span>{textTrimming(email, 22)}</span>
                    </div>
                  </Row>
                </Link>
              )}

              <Row className={cx('row-menu')}>
                <Menu
                  defaultSelectedKeys={[routes.PAGE_MAIN]}
                  selectedKeys={[location.pathname]}
                  defaultOpenKeys={[]}
                  className={cx('menu')}
                  id="dashboard-menu"
                  mode="inline"
                >
                  {role === 'ADMIN' || role === 'MANAGER' ? (
                    <Menu.Item
                      className={cx(
                        'menu-item',
                        (location.pathname === routes.PAGE_DASHBOARD ||
                          location.pathname === routes.PAGE_MAIN) &&
                          'menu-item-active',
                      )}
                      icon={<DashboardIcon />}
                      key={routes.PAGE_MAIN}
                    >
                      <Link to={routes.PAGE_MAIN}>{LANGUAGE.menu.dashboard}</Link>
                    </Menu.Item>
                  ) : null}

                  <SubMenu
                    key={routes.PAGE_CONTROL_PANEL}
                    className={cx('menu-subtitle', searchValueSubMenu() && 'menu-subtitle-active')}
                    id="control-panel-menu"
                    icon={<ControlPaneIcon />}
                    title={
                      <Link to={routes.PAGE_CONTROL_PANEL}>
                        <span className={cx('menu-subtitle-content')}>
                          {LANGUAGE.menu.controlPanel}
                        </span>
                      </Link>
                    }
                  >
                    <Menu.Item
                      className={cx(
                        'menu-item',
                        'menu-item-submenu',
                        location.pathname === routes.PAGE_AGE_CHECK && 'menu-item-submenu-active',
                      )}
                      id="age-check-menu"
                      key={routes.PAGE_AGE_CHECK}
                    >
                      <Link to={routes.PAGE_AGE_CHECK}>{LANGUAGE.menu.subMenuAgeCheck}</Link>
                    </Menu.Item>
                    <Menu.Item
                      className={cx(
                        'menu-item',
                        'menu-item-submenu',
                        location.pathname === routes.PAGE_IMAGES_APPROVAL &&
                          'menu-item-submenu-active',
                      )}
                      id="image-approval-menu"
                      key={routes.PAGE_IMAGES_APPROVAL}
                    >
                      <Link to={routes.PAGE_IMAGES_APPROVAL}>
                        {LANGUAGE.menu.subMenuImagesApproval}
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      className={cx(
                        'menu-item',
                        'menu-item-submenu',
                        location.pathname === routes.PAGE_ANNUAL_INCOME &&
                          'menu-item-submenu-active',
                      )}
                      id="annual-income-menu"
                      key={routes.PAGE_ANNUAL_INCOME}
                    >
                      <Link to={routes.PAGE_ANNUAL_INCOME}>
                        {LANGUAGE.menu.subMenuAnnualIncome}
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      className={cx(
                        'menu-item',
                        'menu-item-submenu',
                        location.pathname === routes.PAGE_TEXT_CHECK && 'menu-item-submenu-active',
                      )}
                      id="text-check-menu"
                      key={routes.PAGE_TEXT_CHECK}
                    >
                      <Link to={routes.PAGE_TEXT_CHECK}>{LANGUAGE.menu.subMenuTextCheck}</Link>
                    </Menu.Item>
                    <Menu.Item
                      className={cx(
                        'menu-item',
                        'menu-item-submenu',
                        location.pathname === routes.PAGE_VIOLATION_REPORT &&
                          'menu-item-submenu-active',
                      )}
                      id="violation-report-menu"
                      key={routes.PAGE_VIOLATION_REPORT}
                    >
                      <Link to={routes.PAGE_VIOLATION_REPORT}>
                        {LANGUAGE.menu.subMenuViolationReport}
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      className={cx(
                        'menu-item',
                        'menu-item-submenu',
                        location.pathname === routes.PAGE_FEEDBACK && 'menu-item-submenu-active',
                      )}
                      key={routes.PAGE_FEEDBACK}
                      id="feedback-menu"
                    >
                      <Link to={routes.PAGE_FEEDBACK}>{LANGUAGE.menu.subMenuFeedback}</Link>
                    </Menu.Item>
                    <Menu.Item
                      className={cx(
                        'menu-item',
                        'menu-item-submenu',
                        location.pathname === routes.PAGE_MESSAGES && 'menu-item-submenu-active',
                      )}
                      key={routes.PAGE_MESSAGES}
                      id="messages-menu"
                    >
                      <Link to={routes.PAGE_MESSAGES}>{LANGUAGE.menu.subMenuAdminMessages}</Link>
                    </Menu.Item>
                    <Menu.Item
                      className={cx(
                        'menu-item',
                        'menu-item-submenu',
                        location.pathname === routes.PAGE_NOTIFICATION_TEMPLATES &&
                          'menu-item-submenu-active',
                      )}
                      key={routes.PAGE_NOTIFICATION_TEMPLATES}
                      id="messages-menu"
                    >
                      <Link to={routes.PAGE_NOTIFICATION_TEMPLATES}>
                        {LANGUAGE.menu.subMenuNotificationTemplates}
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  {role === 'ADMIN' ? (
                    <Menu.Item
                      className={cx(
                        'menu-item',
                        location.pathname === routes.PAGE_HISTORY && 'menu-item-active',
                      )}
                      icon={<HistoryIcon />}
                      key={routes.PAGE_HISTORY}
                      id="history-menu"
                    >
                      <Link to={routes.PAGE_HISTORY}>{LANGUAGE.menu.history}</Link>
                    </Menu.Item>
                  ) : null}
                  {role === 'ADMIN' ? (
                    <Menu.Item
                      className={cx(
                        'menu-item',
                        location.pathname === routes.PAGE_USERS && 'menu-item-active',
                      )}
                      icon={<UsersIcon />}
                      key={routes.PAGE_USERS}
                      id="users-menu"
                    >
                      <Link to={routes.PAGE_USERS}>{LANGUAGE.menu.users}</Link>
                    </Menu.Item>
                  ) : null}
                  {role === 'ADMIN' ? (
                    <Menu.Item
                      className={cx(
                        'menu-item',
                        location.pathname === routes.PAGE_STAFF && 'menu-item-active',
                      )}
                      icon={<StaffIcon />}
                      key={routes.PAGE_STAFF}
                      id="staff-menu"
                    >
                      <Link to={routes.PAGE_STAFF}>{LANGUAGE.menu.staff}</Link>
                    </Menu.Item>
                  ) : null}
                  {role === 'ADMIN' || role === 'MANAGER' ? (
                    <Menu.Item
                      className={cx(
                        'menu-item',
                        location.pathname === routes.PAGE_STATISTICS && 'menu-item-active',
                      )}
                      icon={<StatisticsIcon />}
                      key={routes.PAGE_STATISTICS}
                      id="statistics-menu"
                    >
                      <Link to={routes.PAGE_STATISTICS}>{LANGUAGE.menu.statistics}</Link>
                    </Menu.Item>
                  ) : null}
                  <Menu.Item
                    className={cx(
                      'menu-item',
                      'menu-item-settings',
                      location.pathname === routes.PAGE_SETTINGS && 'menu-item-active',
                    )}
                    icon={<SettingsIcon />}
                    key={routes.PAGE_SETTINGS}
                  >
                    <Link to={routes.PAGE_SETTINGS}>{LANGUAGE.menu.settings}</Link>
                  </Menu.Item>
                </Menu>
              </Row>

              <Row
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
                className={cx('row-footer')}
              >
                <Toggle />
                <span>{LANGUAGE.menu.closeMenuContent}</span>
              </Row>
            </Row>
          ) : (
            <Row className={cx('container')}>
              <Row className={cx('row-logo')}>
                <Link to={routes.PAGE_MAIN}>
                  <LogoCollapsed />
                </Link>
              </Row>
              <Row className={cx('row-profile')}>
                <Link to={`${routes.PAGE_STAFF_PROFILE}`}>
                  <Tooltip
                    placement="rightTop"
                    title={
                      <div className={cx('tooltip')}>
                        <h3>{name}</h3>
                        <span>{email}</span>
                      </div>
                    }
                  >
                    <Avatar size={45} src={avatar} />
                  </Tooltip>
                </Link>
              </Row>
              <Row className={cx('row-menu')}>
                <Menu
                  defaultSelectedKeys={[routes.PAGE_DASHBOARD]}
                  selectedKeys={[location.pathname]}
                  className={cx('menu')}
                  mode="vertical"
                >
                  <Menu.Item
                    className={cx(
                      'menu-item',
                      (location.pathname === routes.PAGE_MAIN ||
                        location.pathname === routes.PAGE_MAIN) &&
                        'menu-item-active',
                    )}
                    key={routes.PAGE_MAIN}
                  >
                    <Link to={routes.PAGE_MAIN}>
                      <DashboardIcon />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key={routes.PAGE_CONTROL_PANEL}
                    className={cx('menu-subtitle', searchValueSubMenu() && 'menu-subtitle-active')}
                    title={
                      <Link to={routes.PAGE_CONTROL_PANEL}>
                        <ControlPaneIcon />
                      </Link>
                    }
                  >
                    <Menu.Item className={cx('menu-item')} key={routes.PAGE_AGE_CHECK}>
                      <Link to={routes.PAGE_AGE_CHECK}>{LANGUAGE.menu.subMenuAgeCheck}</Link>
                    </Menu.Item>
                    <Menu.Item className={cx('menu-item')} key={routes.PAGE_IMAGES_APPROVAL}>
                      <Link to={routes.PAGE_IMAGES_APPROVAL}>
                        {LANGUAGE.menu.subMenuImagesApproval}
                      </Link>
                    </Menu.Item>
                    <Menu.Item className={cx('menu-item')} key={routes.PAGE_ANNUAL_INCOME}>
                      <Link to={routes.PAGE_ANNUAL_INCOME}>
                        {LANGUAGE.menu.subMenuAnnualIncome}
                      </Link>
                    </Menu.Item>
                    <Menu.Item className={cx('menu-item')} key={routes.PAGE_TEXT_CHECK}>
                      <Link to={routes.PAGE_TEXT_CHECK}>{LANGUAGE.menu.subMenuTextCheck}</Link>
                    </Menu.Item>
                    <Menu.Item className={cx('menu-item')} key={routes.PAGE_VIOLATION_REPORT}>
                      <Link to={routes.PAGE_VIOLATION_REPORT}>
                        {LANGUAGE.menu.subMenuViolationReport}
                      </Link>
                    </Menu.Item>
                    <Menu.Item className={cx('menu-item')} key={routes.PAGE_FEEDBACK}>
                      <Link to={routes.PAGE_FEEDBACK}>{LANGUAGE.menu.subMenuFeedback}</Link>
                    </Menu.Item>
                    <Menu.Item className={cx('menu-item')} key={routes.PAGE_MESSAGES}>
                      <Link to={routes.PAGE_MESSAGES}>{LANGUAGE.menu.subMenuAdminMessages}</Link>
                    </Menu.Item>
                    <Menu.Item className={cx('menu-item')} key={routes.PAGE_MESSAGES}>
                      <Link to={routes.PAGE_MESSAGES}>
                        {LANGUAGE.menu.subMenuNotificationTemplates}
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <Menu.Item
                    className={cx(
                      'menu-item',
                      location.pathname === routes.PAGE_HISTORY && 'menu-item-active',
                    )}
                    key={routes.PAGE_HISTORY}
                  >
                    <Tooltip
                      placement="rightTop"
                      title={
                        <div className={cx('tooltip')}>
                          <h3>{LANGUAGE.menu.history}</h3>
                        </div>
                      }
                    >
                      <Link to={routes.PAGE_HISTORY}>
                        <HistoryIcon />
                      </Link>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item
                    className={cx(
                      'menu-item',
                      location.pathname === routes.PAGE_USERS && 'menu-item-active',
                    )}
                    key={routes.PAGE_USERS}
                  >
                    <Tooltip
                      placement="rightTop"
                      title={
                        <div className={cx('tooltip')}>
                          <h3>{LANGUAGE.menu.users}</h3>
                        </div>
                      }
                    >
                      <Link to={routes.PAGE_USERS}>
                        <UsersIcon />
                      </Link>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item
                    className={cx(
                      'menu-item',
                      location.pathname === routes.PAGE_STAFF && 'menu-item-active',
                    )}
                    key={routes.PAGE_STAFF}
                  >
                    <Tooltip
                      placement="rightTop"
                      title={
                        <div className={cx('tooltip')}>
                          <h3>{LANGUAGE.menu.staff}</h3>
                        </div>
                      }
                    >
                      <Link to={routes.PAGE_STAFF}>
                        <StaffIcon />
                      </Link>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item
                    className={cx(
                      'menu-item',
                      location.pathname === routes.PAGE_STATISTICS && 'menu-item-active',
                    )}
                    key={routes.PAGE_STATISTICS}
                  >
                    <Tooltip
                      placement="rightTop"
                      title={
                        <div className={cx('tooltip')}>
                          <h3>{LANGUAGE.menu.statistics}</h3>
                        </div>
                      }
                    >
                      <Link to={routes.PAGE_STATISTICS}>
                        <StatisticsIcon />
                      </Link>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item
                    className={cx(
                      'menu-item',
                      'menu-item-settings',
                      location.pathname === routes.PAGE_SETTINGS && 'menu-item-active',
                    )}
                    key={routes.PAGE_SETTINGS}
                  >
                    <Tooltip
                      placement="rightTop"
                      title={
                        <div className={cx('tooltip')}>
                          <h3>{LANGUAGE.menu.settings}</h3>
                        </div>
                      }
                    >
                      <Link to={routes.PAGE_SETTINGS}>
                        <SettingsIcon />
                      </Link>
                    </Tooltip>
                  </Menu.Item>
                </Menu>
              </Row>

              <Row
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
                className={cx('row-footer')}
              >
                <Toggle />
                {collapsed && <span>{LANGUAGE.menu.closeMenuContent}</span>}
              </Row>
            </Row>
          )}
        </>
      ) : (
        <Row className={cx('container-collapsed')}>
          <Row className={cx('row-logo')}>
            <Link to={routes.PAGE_MAIN}>
              <Logo />
            </Link>
          </Row>
          <Space className={cx('row-profile-skeleton')}>
            <Skeleton.Avatar active size={45} />
            <div>
              <Skeleton.Input
                className={cx('skeleton-input')}
                style={{ width: 150, height: 15 }}
                active
                size={52}
              />
              <Skeleton.Input
                className={cx('skeleton-input')}
                style={{ width: 150, height: 15 }}
                active
                size={52}
              />
            </div>
          </Space>
          <Row className={cx('row-menu')}>
            <Menu
              defaultSelectedKeys={[routes.PAGE_MAIN]}
              selectedKeys={[location.pathname]}
              defaultOpenKeys={location.pathname !== '/' ? [routes.PAGE_CONTROL_PANEL] : []}
              className={cx('menu')}
              id="dashboard-menu"
              mode="inline"
            >
              <Skeleton.Input
                className={cx('skeleton-menu')}
                style={{ width: 150, height: 15 }}
                active
                size={52}
              />
              <Skeleton.Input className={cx('skeleton-hr')} />
              <Skeleton.Input
                className={cx('skeleton-footer')}
                style={{ width: 150, height: 15 }}
                active
                size={52}
              />
            </Menu>
          </Row>
          <Row
            onClick={() => {
              setCollapsed(!collapsed);
            }}
            className={cx('row-footer')}
          >
            <Toggle />
            {collapsed && <span>{LANGUAGE.menu.closeMenuContent}</span>}
          </Row>
        </Row>
      )}
    </>
  );
};

MainMenuView.propTypes = {
  collapsed: bool,
  setCollapsed: func,
  name: string,
  email: string,
  id: string,
  avatar: string,
  role: string,
  loading: bool,
  error: objectOf(any),
  nickname: string,
}.isRequired;
