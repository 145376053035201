import { updatePartOfState, handleUnknownActionType } from '../../services/reducer';
import * as actions from './actions';

export const initialState = {
  avatar: null,
  profile: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.GET_AVATAR_LOADING:
      return updatePartOfState(state, {
        loading: true,
      });

    case actions.GET_AVATAR_SUCCESS:
      return updatePartOfState(state, {
        loading: false,
        avatar: action.data.adminAvatar.fileUrl,
      });

    case actions.GET_AVATAR_ERROR:
      return updatePartOfState(state, {
        loading: false,
        error: action.error,
      });

    case actions.INIT_STAFF_PROFILE_LOADING:
      return updatePartOfState(state, {
        profile: [],
        loading: true,
      });

    case actions.INIT_STAFF_PROFILE_SUCCESS:
      return updatePartOfState(state, {
        loading: false,
        profile: action.data,
      });

    case actions.INIT_STAFF_PROFILE_ERROR:
      return updatePartOfState(state, {
        loading: false,
        error: action.error,
      });

    default:
      return handleUnknownActionType(action);
  }
};
