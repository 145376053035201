import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { MainLayout } from '../layouts/main-layout';
import { AuthLayout } from '../layouts/auth-layout';
import { reducer, initialState } from '../store/store-main/reducer';
import { MAIN_LAYOUTS_ROUTES, NOT_FOUND_ROUTE } from './routes-main';
import { MainContext } from './main-context';
import ErrorBoundary from '../components/error-boundary';
import { UseAuth0ProviderWithHistory } from '../hooks/use-auth0-provider-with-history';
import { PrivateRoute } from '../hooks/use-private-route';

import { Loader } from '../components/loader';

const getPaths = routes => routes.map(({ path }) => path);

const AppRoutes = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const createLayoutRoutes = (childrenRoutes, Layout = React.Fragment, isExact) => (
    <Route exact={isExact} path={getPaths(childrenRoutes)}>
      <Layout>
        <React.Suspense fallback={<Loader />}>
          {childrenRoutes.map(({ path, component, exact }) => (
            <ErrorBoundary key={path}>
              <PrivateRoute path={path} component={component} exact={exact} />
            </ErrorBoundary>
          ))}
        </React.Suspense>
      </Layout>
    </Route>
  );

  return (
    <MainContext.Provider value={{ state, dispatch }}>
      <Router>
        <UseAuth0ProviderWithHistory>
          <Switch>
            {createLayoutRoutes(MAIN_LAYOUTS_ROUTES, MainLayout, true)}
            {createLayoutRoutes(NOT_FOUND_ROUTE, MainLayout, false)}
          </Switch>
        </UseAuth0ProviderWithHistory>
      </Router>
    </MainContext.Provider>
  );
};

export default AppRoutes;
