// Main

export const PAGE_MAIN = '/';

// Menu

export const PAGE_DASHBOARD = '/';
export const PAGE_HISTORY = '/history';
export const PAGE_USERS = '/users';
export const PAGE_USER_DETAIL = '/users-detail';
export const PAGE_USER_ACTIONS = '/users-actions';
export const PAGE_STAFF = '/staff';
export const PAGE_STAFF_PROFILE = '/staff-profile';
export const PAGE_STAFF_DETAIL = '/staff-detail';
export const PAGE_STATISTICS = '/statistics';
export const PAGE_SETTINGS = '/settings';
export const PAGE_CONTROL_PANEL = '/control-panel';

// Subtitle

export const PAGE_AGE_CHECK = '/age-check';
export const PAGE_AGE_CHECK_DETAIL = '/age-check-detail';
export const PAGE_IMAGES_APPROVAL = '/images-approval';
export const PAGE_ANNUAL_INCOME = '/annual-income';
export const PAGE_TEXT_CHECK = '/text-check';
export const PAGE_TEXT_CHECK_DETAIL = '/text-check-detail';
export const PAGE_BAD_WORDS_CATALOG = '/bad-words-catalog';
export const PAGE_VIOLATION_REPORT = '/violation-report';
export const PAGE_VIOLATION_REPORT_DETAIL = '/violation-report-detail';
export const PAGE_INTERACTIONS_BETWEEN_USERS = '/abuse-report-actions';
export const PAGE_FEEDBACK = '/feedback';
export const PAGE_FEEDBACK_DETAIL = '/feedback-detail';
export const PAGE_MESSAGES = '/admin-messages';
export const PAGE_NOTIFICATION_TEMPLATES = '/notification-templates';

// Auth

export const PAGE_LOGIN = '/login';
