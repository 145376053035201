export const DEFAULT_LENGTH_TEXT = 40;

export const textTrimming = (text, countSymbols = DEFAULT_LENGTH_TEXT) => {
  if (text !== undefined && text !== null) {
    if (text.length > countSymbols) {
      return `${text.substr(0, countSymbols)}...`;
    }

    if (text.length === countSymbols) {
      return text;
    }
  }

  return text;
};

export const stringIsEmpty = str => {
  if (str.trim() === '') return true;
  return false;
};

export const breakDownIntoTriads = value =>
  value && value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ');

export const breakDownIntoTriadsWithComma = value =>
  value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,');
