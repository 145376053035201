export const GET_AVATAR_LOADING = 'GET_AVATAR_LOADING';
export const GET_AVATAR_SUCCESS = 'GET_AVATAR_SUCCESS';
export const GET_AVATAR_ERROR = 'GET_AVATAR_ERROR';

export const INIT_STAFF_PROFILE_LOADING = 'INIT_STAFF_PROFILE_LOADING';
export const INIT_STAFF_PROFILE_SUCCESS = 'INIT_STAFF_PROFILE_SUCCESS';
export const INIT_STAFF_PROFILE_ERROR = 'INIT_STAFF_PROFILE_ERROR';

export const getAvatarLoading = () => ({
  type: GET_AVATAR_LOADING,
});

export const getAvatarSuccess = ({ data }) => ({
  type: GET_AVATAR_SUCCESS,
  data,
});

export const getAvatarError = error => ({
  type: GET_AVATAR_ERROR,
  error,
});

export const initStaffProfileLoading = () => ({
  type: INIT_STAFF_PROFILE_LOADING,
});

export const initStaffProfileSuccess = ({ data }) => ({
  type: INIT_STAFF_PROFILE_SUCCESS,
  data,
});

export const initStaffProfileError = error => ({
  type: INIT_STAFF_PROFILE_ERROR,
  error,
});
