import React from 'react';
import { func, bool } from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { CURRENT_USER } from '../../constant/local-storage';
import { setValueLocalStorage } from '../../services/local-storage';
import { checkForValue } from '../../services/check-for-a-value';
import { MainMenuView } from './main-menu.view';
import { initialState, reducer } from '../../store/store-main/reducer';
import { useAccessToken } from '../../hooks/use-access-token';
import { getAdminAvatar, retrieveProfile } from '../../store/store-main/api';

export const MainMenu = ({ collapsed, setCollapsed }) => {
  const { user, isLoading, error } = useAuth0();
  const { accessToken } = useAccessToken();
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    if (accessToken) {
      getAdminAvatar({ dispatch, accessToken });
      if (checkForValue(user)) {
        setValueLocalStorage(CURRENT_USER, JSON.stringify(user));
      }
    }
  }, [accessToken, user]);

  React.useEffect(() => {
    if (checkForValue(user)) {
      accessToken && retrieveProfile({ dispatch, accessToken });
    }
  }, [accessToken, user]);

  React.useEffect(() => {
    state?.profile?.role && setValueLocalStorage('role', state?.profile?.role);
    state?.profile?.userIdHash && setValueLocalStorage('userIdHash', state?.profile?.userIdHash);
  }, [state?.profile]);

  return (
    <MainMenuView
      name={user?.name}
      familyName={user?.family_name}
      email={user?.email}
      avatar={state?.avatar}
      loading={isLoading}
      collapsed={collapsed}
      setCollapsed={setCollapsed}
      id={user?.sub}
      role={state?.profile?.role}
      error={error}
    />
  );
};

MainMenu.propTypes = {
  collapsed: bool,
  setCollapsed: func,
};
