import languageEN from './languages/en/en.json';
import languageJP from './languages/jp/jp.json';
import { getLocationLanguage } from './services/determine-location-browser';

const supportedLanguages = [
  { id: 1, abbreviation: 'en', language: languageEN },
  { id: 2, abbreviation: 'ja', language: languageJP },
];

// In order to change the language of the application, you must change the variable REACT_APP_LANGUAGE (1 or 2) and restart the application

export const LANGUAGE_PATH = getLocationLanguage(
  Number(process.env.REACT_APP_LANGUAGE),
  supportedLanguages,
);
