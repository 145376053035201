import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames/bind';
import { string, func } from 'prop-types';
import { ReactComponent as NotFoundIcon } from '../../static/icon/errors/error.svg';
import { LANGUAGE } from '../../services/language';
import { ERROR_GENERAL } from '../../constant/messages';
import style from './error.module.scss';

const cx = classNames.bind(style);

const { buttons } = LANGUAGE;

export const ErrorView = ({ error = LANGUAGE.error.generalError, updatePage }) => (
  <div className={cx('container')}>
    <NotFoundIcon />
    <h2 className={cx('title')}>{error}</h2>
    <Button onClick={updatePage} className={cx('button')} type="primary">
      {buttons.buttonUpdatePage}
    </Button>
  </div>
);

ErrorView.propTypes = {
  error: string,
  updatePage: func,
};
